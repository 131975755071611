/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import IconFa from 'react-native-vector-icons/dist/FontAwesome';
import IconFeather from 'react-native-vector-icons/dist/Feather';
import IconMI from 'react-native-vector-icons/dist/MaterialIcons';

import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import * as S from '../styles';
import { StyledDefaultTheme, Typography } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { AppointmentTypes } from '~/contexts/types';
import { TypesEnum, scheduleTypes } from '~/enums/appointment';
import { useAppointment } from '~/hooks/appointment';
import TestTubeIcon from '~/components/Icons/TestTubeIcon';
import ToothIcon from '~/components/Icons/ToothIcon';
import VideoCall from '~/components/Icons/VideoCall';
import { capitalize } from '~/utils/strings';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';

interface DetailProps {
  icon: JSX.Element;
  title: string;
}

export const Card = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { isDeepLink } = DeepLink.useParams();

  const { appointment } = useAppointment();
  const { onixCode } = useAuth();

  const [currentAppointment, setCurrentAppointment] = useState<AppointmentTypes | null>();
  const [showViewApointment, setShowViewApointment] = useState<boolean>(false);
  const [detail, setDetail] = useState<DetailProps | null>(null);

  const iconTypes: JSX.Element[] = [
    <IconFa
      name="stethoscope"
      size={28}
      color={theme.colors.primary ?? StyledDefaultTheme.colors.primary}
    />,
    <VideoCall
      width={28}
      height={28}
      color={theme.colors.primary ?? StyledDefaultTheme.colors.primary}
    />,
    <Icon
      name="file-text"
      size={28}
      color={theme.colors.primary ?? StyledDefaultTheme.colors.primary}
    />,
    <TestTubeIcon color={theme.colors.primary ?? StyledDefaultTheme.colors.primary} />,
    <Icon
      name="video"
      size={28}
      color={theme.colors.primary ?? StyledDefaultTheme.colors.primary}
    />,
    <IconFa
      name="stethoscope"
      size={28}
      color={theme.colors.primary ?? StyledDefaultTheme.colors.primary}
    />,
    <ToothIcon />,
    <Icon
      name="video"
      size={28}
      color={theme.colors.primary ?? StyledDefaultTheme.colors.primary}
    />,
  ];

  const onixConecta = [];

  const isConectaTelemedicine =
    onixConecta?.includes(onixCode?.onix_code) && currentAppointment?.type === 1;

  const isAppoitmentPersonalBmg =
    onixConecta?.includes(onixCode?.onix_code) && currentAppointment?.type === 0;

  const shouldShowViewApointment = () => {
    if (isConectaTelemedicine) return true;
    if (isAppoitmentPersonalBmg) return false;

    return (
      currentAppointment?.type !== TypesEnum.Terapiaonline &&
      currentAppointment?.type !== TypesEnum.Surgical &&
      currentAppointment?.type !== undefined
    );
  };

  const getDetails = (): DetailProps => {
    const scheduleTypeOrType = currentAppointment?.scheduleType ?? currentAppointment?.type;

    return {
      icon: iconTypes[scheduleTypeOrType] || iconTypes[TypesEnum.Appointment],
      title: scheduleTypes[scheduleTypeOrType] || '-',
    };
  };

  useEffect(() => {
    if (appointment) {
      setCurrentAppointment(appointment);
    }
  }, [appointment]);

  useEffect(() => {
    if (currentAppointment && onixCode) {
      setShowViewApointment(shouldShowViewApointment());
    }
  }, [currentAppointment, onixCode]);

  useEffect(() => {
    if (currentAppointment) {
      setDetail(getDetails());
    }
  }, [currentAppointment]);

  return (
    <S.ContainerCard>
      <S.WrapperCard>
        <S.WrapperCardContent>
          <S.WrapperCardIcon>
            {!isConectaTelemedicine ? (
              detail?.icon || iconTypes[currentAppointment?.type]
            ) : (
              <IconMI name="personal-video" size={32} color={StyledDefaultTheme.colors.primary} />
            )}
          </S.WrapperCardIcon>
          <S.WrapperCardHeader>
            <Typography variant="bodyHighlight1" color={theme.colors.black}>
              {detail?.title
                ? detail.title === 'Checkup'
                  ? 'Check-Up Médico'
                  : detail?.title
                : '-'}
            </Typography>
            <Typography variant="body2" color={theme.colors.black}>
              {currentAppointment?.specialty && capitalize(currentAppointment?.specialty.name)}
              {currentAppointment?.type === TypesEnum.Terapiaonline && 'Psicologia'}
            </Typography>
          </S.WrapperCardHeader>
        </S.WrapperCardContent>
        <S.WrapperCardActions hasBorder={showViewApointment}>
          {showViewApointment && !isDeepLink && (
            <S.ActionRight
              onPress={() => {
                navigation.navigate('MyAppointments');
              }}>
              <S.WrapperActionIcon>
                <Typography variant="link" color={theme?.colors.primary}>
                  Acompanhar solicitação
                </Typography>
                <S.WrapperIconSize>
                  <IconFeather
                    name="chevron-right"
                    size={30}
                    color={theme.colors.primary ?? StyledDefaultTheme.colors.primary}
                  />
                </S.WrapperIconSize>
              </S.WrapperActionIcon>
            </S.ActionRight>
          )}
        </S.WrapperCardActions>
      </S.WrapperCard>
    </S.ContainerCard>
  );
};

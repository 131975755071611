import React, { useMemo } from 'react';

import { ImageSourcePropType, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/myBenefits/desktop.png';
import mobileBackground from '../../assets/images/myBenefits/mobile.png';
import Phone24 from '../BenefitsPresentation/components/Icons/Phone24';
import { BenefitsCard } from './components/BenefitsCard';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Typography } from '~/components/@hello-ui';
import Star from '~/components/Icons/Star';
import CircleGroup from '~/components/Icons/CircleGroup';
import Medicaments from '~/components/Icons/Medicaments';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import Plant from '~/components/Icons/Plant';
import Segurity from '~/components/Icons/Segurity';
import House from '~/components/Icons/House';
import CheckUpIcon from '~/components/Icons/CheckUpIcon';
import ToothIcon from '~/components/Icons/ToothIcon';
import ExamIcon from '~/components/Icons/Exam';
import { PrizeIcon } from '~/components/Icons/Prize';
import Family from '~/components/Icons/Family';
import PhoneIcon from '~/components/Icons/PhoneIcon';
import VideoCall from '~/components/Icons/VideoCall';
import { useAuth } from '~/auth/legacy/useAuth';
import { ToTopButton } from '~/components/@hello-ui/Listing/components/toTop';
import { PageWithCardContext } from '~/components/@hello-ui/PageWithCard/context/PageWithCardContext';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { isItauOnix } from '~/helpers/itau';
import Calculator from '~/components/Icons/Calculator';
export const MyBenefits = (): JSX.Element => {
  const navigation = useNavigation();
  const { onixCode } = useAuth();

  const backgroundImage = (
    Platform.OS === 'web' ? desktopBackground : mobileBackground
  ) as ImageSourcePropType;
  const { checkIsEnabledFeature } = useAuth();
  const benefitsList = useMemo(
    () => [
      {
        title: 'Assistência Funeral',
        description:
          'Em momentos sensíveis, uma assistência que ajuda em gastos inesperados com profissionais especializados.',
        route: 'BenefitsFunerals',
        names: ['funeral'],
        icon: <CircleGroup width={24} height={24} />,
        visible: checkIsEnabledFeature(['funeral', 'funeral_memorial', 'funeral_igs']),
      },
      {
        title: 'Assistência Residencial',
        description:
          'Proteja sua residência com serviços que trazem tranquilidade em situações de emergências domésticas.',
        route: 'BenefitsResidentialAssistant',
        names: ['residential_assistant'],
        icon: <House width={24} height={24} />,
        visible: checkIsEnabledFeature('residential_assistant'),
      },
      {
        title: 'Checkup',
        description: `Nas melhores clínicas e laboratórios, ${isItauOnix(onixCode?.onix_code) ? 'para você ficar com a saúde em dia.' : 'para sua família toda ficar com a saúde em dia.'}`,
        footerDescription: '*Quantidade conforme plano',
        route: 'BenefitsCheckup',
        names: ['checkup'],
        icon: <CheckUpIcon width={24} height={24} />,
        visible: checkIsEnabledFeature('checkup'),
      },
      {
        title: 'Clube de Benefícios',
        description: 'Benefícios especiais e descontos em compras das marcas que você mais gosta!',
        route: 'BenefitsFinancialClub',
        names: ['discount_club'],
        icon: <Star width={24} height={24} />,
        visible: checkIsEnabledFeature('discount_club'),
      },
      {
        title: 'Programa de Educação Financeira',
        description: 'Plano de ensino de gestão financeira',
        route: 'BenefitsFinancialEducation',
        names: ['programa_educacao_financeira'],
        icon: <Calculator width={24} height={24} />,
        visible: checkIsEnabledFeature('programa_educacao_financeira'),
      },
      {
        title: 'Consultas',
        description:
          'Tenha acesso à uma rede de clínicas médicas e especialistas com atendimento em todo o território nacional.',
        route: 'BenefitsServicesConsultations',
        names: ['health_network'],
        icon: <StethoscopeIcon width={24} height={24} />,
        visible: checkIsEnabledFeature('health_network'),
      },
      {
        title: 'Consulta por Vídeo',
        description:
          'Consultas com comodidade e a facilidade que você precisa, com especialistas prontos a te atender.',
        route: 'BenefitsNetwork',
        names: ['telemedicine'],
        icon: <VideoCall width={24} height={24} />,
        visible: checkIsEnabledFeature('telemedicine'),
      },
      {
        title: 'Consulta por Vídeo 24/7',
        description:
          'Consulta com Clínico Geral, feitas através do seu celular. A qualquer dia e a qualquer hora.',
        route: 'BenefitsNetwork',
        names: ['telemedicine_24'],
        icon: <Phone24 width={24} height={24} />,
        visible: checkIsEnabledFeature('telemedicine_24'),
      },
      {
        title: 'Dentista',
        description:
          'Dentistas e ortodontistas para você fazer suas consultas, exames ou tratamentos odontológicos com toda qualidade.',
        route: 'BenefitsNetwork',
        names: ['dentistry'],
        icon: <ToothIcon width={24} height={24} />,
        visible: checkIsEnabledFeature('dentistry'),
      },
      {
        title: 'Exames',
        description:
          'Exames laboratoriais e de imagem em excelentes clínicas com preços  reduzidos.',
        route: 'BenefitsNetwork',
        names: ['health_network'],
        icon: <ExamIcon width={24} height={24} />,
        visible: checkIsEnabledFeature('health_network'),
      },
      {
        title: 'Medicamentos com desconto',
        description:
          'Desconto garantido em sites, televendas e mais de 22 mil farmácias no Brasil.',
        route: 'PharmacyBenefit',
        names: ['medicine_discounts'],
        icon: <Medicaments width={24} height={24} />,
        visible: checkIsEnabledFeature('medicine_discounts'),
      },
      {
        title: 'Número da Sorte',
        description: 'Participe de sorteios mensais com prêmios no valor de R$ 20 mil.',
        route: 'BenefitsCapitalization',
        names: ['capitalization_title'],
        icon: <PrizeIcon width={24} height={24} />,
        visible: checkIsEnabledFeature('capitalization_title'),
      },
      {
        title: 'Programa Familiar',
        description:
          'Adicione até quatro dependentes no programa, para que todos da família também tenham acesso aos melhores benefícios.',
        route: 'Dependents',
        names: ['dependents'],
        icon: <Family width={24} height={24} />,
        visible: checkIsEnabledFeature('dependents'),
      },
      {
        title: 'Seguro Morte ou Invalidez por Acidente',
        description:
          'Seguro que garante o pagamento de indenização em decorrência de lesão física permanente.',
        route: 'BenefitsNetwork',
        names: ['life_insurance'],
        icon: <Segurity width={24} height={24} />,
        visible: checkIsEnabledFeature('life_insurance'),
      },
      {
        title: 'Teleorientação 24/7',
        description:
          'Orientação com profissional de saúde por telefone, a qualquer hora do dia e qualquer dia da semana.',
        route: 'BenefitsInsuranceDeathDisability',
        names: ['teleorientation'],
        icon: <PhoneIcon width={24} height={24} />,
        visible: checkIsEnabledFeature('teleorientation'),
      },
      {
        title: 'Terapia psicológica por vídeo',
        description:
          'Consultas com psicólogos feitas por vídeo, pra você cuidar da saúde mental no conforto de casa.',
        route: 'PsychologicalAssistance',
        names: ['psicologia_online'],
        icon: <Plant width={24} height={24} />,
        visible: checkIsEnabledFeature('psicologia_online'),
      },
    ],
    [],
  );

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        title: 'Meus Benefícios',
        titleStartContent: <Star color="white" />,
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <Typography variant="body2">
        Os melhores serviços para cuidar da sua saúde e bem estar, com a{' '}
        <Typography variant="bodyHighlight2">
          qualidade de um serviço particular e preços que cabem no seu bolso.
        </Typography>{' '}
        Conheça quais são os seus benefícios:
      </Typography>
      <View className="mt-20 w-full flex-1 basis-auto mobile:mt-8">
        <BenefitsCard benefitsFilter={benefitsList} />
      </View>
      <PageWithCardContext.Consumer>
        {({ currentScroll }) => (
          <View className="w-ful mt-48 pb-32">
            <ToTopButton isScroll scrollRef={currentScroll} />
          </View>
        )}
      </PageWithCardContext.Consumer>
    </PageWithCard>
  );
};

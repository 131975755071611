import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export function InformationCircleIcon({ width, height }: IconProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <G clipPath="url(#clip0_16388_29841)" fill="#2374C5">
        <Path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z" />
        <Path d="M12 10h-1a1 1 0 000 2h1v6a1 1 0 002 0v-6a2 2 0 00-2-2zM12 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
      </G>
      <Defs>
        <ClipPath id="clip0_16388_29841">
          <Path fill="#fff" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

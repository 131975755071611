import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { StyledDefaultTheme } from '../@hello-ui/Theme';

export const HealthProfessionalItem = styled.TouchableOpacity<{
  roundedTop: boolean;
  roundedBottom: boolean;
}>`
  border-top-right-radius: ${({ roundedTop }) => (roundedTop ? 8 : 0)};
  border-top-left-radius: ${({ roundedTop }) => (roundedTop ? 8 : 0)};

  border-bottom-right-radius: ${({ roundedBottom }) => (roundedBottom ? 8 : 0)};
  border-bottom-left-radius: ${({ roundedBottom }) => (roundedBottom ? 8 : 0)};
`;

export const HealthProfessionalText = styled.Text<{ color?: string }>`
  font-size: ${Platform.OS === 'web' ? '14px' : '14px'};
  font-style: normal;
  font-family: 'Poppins';
  font-weight: 400;
  line-height: ${Platform.OS === 'web' ? '24px' : '18px'};
  letter-spacing: 0.25px;
  color: ${({ color }) => color ?? StyledDefaultTheme.colors.paragraph};
`;

import React from 'react';
import { View } from 'react-native';
import { HealthProfessionalItem } from './HealthProfessionalItem';
import { Typography } from '../@hello-ui';
import { Spacing } from '../Spacing';
import { useNavigation } from '@react-navigation/native';

export interface IHealthProfessionalItem {
  label: string;
  professionalType: string;
}

interface IHealthProfessionalProps {
  items: IHealthProfessionalItem[];
  title: string;
  subtitle: string;
  benefit: string;
}

export const HealthProfessional: React.FC<IHealthProfessionalProps> = ({
  items,
  title,
  subtitle,
  benefit,
}) => {
  const navigation = useNavigation();

  return (
    <View style={{ flexDirection: 'column', justifyContent: 'center', maxWidth: 530 }}>
      <Typography variant="titleXsmall" color="black">
        {title}
      </Typography>

      <Spacing bottom={8}>
        <Typography variant="helperText" color="paragraph">
          {subtitle}
        </Typography>
      </Spacing>
      <View style={{ flexDirection: 'column', justifyContent: 'center', maxWidth: 530 }}>
        {items.map((item, index) => (
          <Spacing bottom={1}>
            <HealthProfessionalItem
              item={item}
              key={`HealthProfessionalItem-${item.label}`}
              onPress={() => {
                navigation.navigate('BenefitsVideoAppointmentsSchedule', {
                  benefit,
                  professionalType: item.professionalType,
                });
              }}
              roundedTop={index === 0}
              roundedBottom={index === items.length - 1}
            />
          </Spacing>
        ))}
      </View>
    </View>
  );
};

import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const Calculator = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 9C2.579 9 0 10.505 0 12.5V20.5C0 22.495 2.579 24 6 24C9.421 24 12 22.495 12 20.5V12.5C12 10.505 9.421 9 6 9ZM10 16.5C10 17.029 8.481 18 6 18C3.519 18 2 17.029 2 16.5V15.152C3.046 15.685 4.435 16 6 16C7.565 16 8.954 15.685 10 15.152V16.5ZM6 11C8.481 11 10 11.971 10 12.5C10 13.029 8.481 14 6 14C3.519 14 2 13.029 2 12.5C2 11.971 3.519 11 6 11ZM6 22C3.519 22 2 21.029 2 20.5V19.152C3.046 19.685 4.435 20 6 20C7.565 20 8.954 19.685 10 19.152V20.5C10 21.029 8.481 22 6 22ZM24 5V19C24 21.757 21.757 24 19 24H14C13.447 24 13 23.552 13 23C13 22.448 13.447 22 14 22H19C20.654 22 22 20.654 22 19V5C22 3.346 20.654 2 19 2H9C7.346 2 6 3.346 6 5V6C6 6.552 5.553 7 5 7C4.447 7 4 6.552 4 6V5C4 2.243 6.243 0 9 0H19C21.757 0 24 2.243 24 5ZM13 10C12.447 10 12 9.552 12 9C12 8.448 12.447 8 13 8H18V6H10V6.5C10 7.052 9.553 7.5 9 7.5C8.447 7.5 8 7.052 8 6.5V6C8 4.897 8.897 4 10 4H18C19.103 4 20 4.897 20 6V8C20 9.103 19.103 10 18 10H13ZM14 18C14 17.448 14.447 17 15 17H19C19.553 17 20 17.448 20 18C20 18.552 19.553 19 19 19H15C14.447 19 14 18.552 14 18ZM14 14V13C14 12.448 14.447 12 15 12C15.553 12 16 12.448 16 13V14C16 14.552 15.553 15 15 15C14.447 15 14 14.552 14 14ZM20 14C20 14.552 19.553 15 19 15C18.447 15 18 14.552 18 14V13C18 12.448 18.447 12 19 12C19.553 12 20 12.448 20 13V14Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default Calculator;

import { createContext } from 'react';
import { AxiosResponse } from 'axios';
import { CreateTelemedicine24h, GetQueue } from '~/services/api/interfaces/conexa';
import {
  LoginType,
  CheckFirstAccessType,
  ConfirmationTokenType,
  CheckConfirmationTokenType,
  MyProductsType,
  SpecialitiesType,
  StatesType,
  CitiesType,
  NeighborhoodsType,
  CardType,
  CardBalanceType,
  CardStatementType,
  MyProfileType,
  BenefitsTypes,
  SearchDrugstoresPayload,
  SearchNetworkPayload,
  Partner,
  SignatureType,
  ClinicType,
  Recharges,
  CreditCard,
  SpecialtyType,
  Specialty,
  SearchByZipCodeRes,
  SearchDrugstoresRes,
  SearchNetworkRes,
  ValidateDeeplinkRes,
  SearchCertifiedTemRedePayload,
  SearchCertifiedTemRedeRes,
  SearchTemFarmaPayload,
  SearchTemFarmaRes,
  TelemedicineDoctor,
  DoctorSchedule,
  TelemedicineAppointmentPayload,
  ProfessionalType,
  TelemedicineHealthcareProfessional,
} from './types';
import { MyAppointments } from '~/@types/appointments';
import { Appointment } from '~/screens/MyAppointments/state/types';
import { UserToken } from '~/@types/auth/user';

export interface ApiInstance {
  getMedexLink(
    cpf: string | undefined,
    onix_code: string | undefined,
  ): { data: any } | PromiseLike<{ data: any }>;
  getEinsteinLink({
    cpf,
    onix_code,
    birthDate,
    name,
  }: {
    cpf: string | undefined;
    onix_code: string | undefined;
    birthDate: string | undefined;
    name: string | undefined;
  }): { data: any } | PromiseLike<{ data: any }>;
  getRegulation: () => Promise<any>;
  getBenefits: (_cardNumber: string | undefined) => Promise<BenefitsTypes>;
  getPartner: (_onix_code: string | undefined) => Promise<Partner>;
  login: (_documentNumber: string, _password: string) => Promise<LoginType>;
  checkFirstAccess: (_documentNumber: string) => Promise<CheckFirstAccessType>;
  passwordFirstAccess: (_documentNumber: string, _password: string, _code: string) => Promise<any>;
  checkToken: (_code: number) => Promise<CheckConfirmationTokenType>;
  sendToken: (
    _documentNumber: string,
    _onixcCde?: string,
    smsHashcode?: string[],
  ) => Promise<ConfirmationTokenType>;
  getMySignature: () => Promise<SignatureType>;
  myProducts: () => Promise<MyProductsType>;
  getMyClinic: () => Promise<ClinicType>;
  specialities: (_type?: SpecialtyType) => Promise<SpecialitiesType>;
  findSpecialtyByName: (_name: string) => Promise<Specialty>;
  getMyAppointments: ({ cpf, page, perPage, finished }: MyAppointments) => Promise<any>;
  getMyAppointmentById: (id: number, cpf: string) => Promise<{ data: Appointment[] }>;
  states: (value?: string) => Promise<StatesType>;
  cities: (_id: number, value?: string) => Promise<CitiesType>;
  neighborhoods: (_id: number, value?: string) => Promise<NeighborhoodsType>;
  createSchedule: (_payload: any) => Promise<{}>;
  cardList: (_user: any) => Promise<{ data: CardType['data'][] }>;
  cardStatement: (
    _documentNumber: string,
    _cardNumber: string,
  ) => Promise<{ data: CardStatementType[] }>;
  cardBalance: (_documentNumber: string, _cardNumber: string) => Promise<{ data: CardBalanceType }>;
  getCardNumberByCpfOnix: (cpf: string, onix: string) => Promise<any>;
  myProfile: () => Promise<MyProfileType>;
  updateProfile: (values: any) => Promise<any>;
  updatePassword: (_oldPassword: string, _newPassword: string) => Promise<any>;
  updateTemPayPassword: (_cardNumber: string, _password: string) => Promise<any>;
  updateProfileAddress: (values: any) => Promise<any>;
  recharge: (values: any) => Promise<any>;
  rechargeWithRegisteredCreditCard: (values: Recharges) => Promise<any>;
  sendingSurgicalProcedure: (values: any) => Promise<any>;
  createCreditCard: (values: CreditCard) => Promise<any>;
  deleteCreditCard: (id: number) => Promise<any>;
  getCreditCards: (cpf: number) => Promise<any>;
  searchZipCode: (address: string) => Promise<AxiosResponse<SearchByZipCodeRes>>;
  searchByLatLng: (lat: number, lng: number) => Promise<any>;
  createDependent: (values: any) => Promise<any>;
  getDependents: (cpf: string) => Promise<any>;
  updateDependent: (values: any) => Promise<any>;
  deleteDependent: (cpf: string) => Promise<any>;
  luckyNumber: (_cardNumber: string) => Promise<any>;
  lotteryNumbers: () => Promise<any>;
  hasPrivacyPolicy: (
    _cardNumber: string,
    _documentNumber: string,
    policyType?: string,
  ) => Promise<any>;
  hasPrivacyPolicyUpdate: (cpf: string, policyType: string) => Promise<any>;
  returnPrivacyPolicy: () => Promise<any>;
  acceptPrivacyPolicy: (
    _cardNumber: string,
    _documentNumber: string,
    _ip: string,
    _policyType?: string,
  ) => Promise<any>;
  searchDrugstores: (
    _payload: SearchDrugstoresPayload,
  ) => Promise<AxiosResponse<SearchDrugstoresRes[]>>;
  searchTemFarma: (_payload: SearchTemFarmaPayload) => Promise<AxiosResponse<SearchTemFarmaRes>>;
  searchCertifiedTemRede: (
    _type: 'clinic' | 'laboratory' | 'ontological_clinic',
    _payload: SearchCertifiedTemRedePayload,
  ) => Promise<AxiosResponse<SearchCertifiedTemRedeRes>>;
  searchNetwork: (payload: SearchNetworkPayload) => Promise<AxiosResponse<SearchNetworkRes[]>>;
  telemedicine24h: (
    _cpf: string,
    _birthDate: string,
    _name: string,
    _onix: string,
    _dependentCpf?: string | null,
  ) => Promise<CreateTelemedicine24h>;
  telemedicine24hUrl: (_patientId: number) => Promise<GetQueue>;
  getPlanejadinDeeplink: (_cpf: string) => Promise<any>;
  telemedicine24hCancelQueue: (_patientId: number) => Promise<any>;
  telemedicineSpecialties: () => Promise<AxiosResponse<Specialty[]>>;
  specialtyDoctors: (specialtyId: number) => Promise<AxiosResponse<TelemedicineDoctor[]>>;
  healthcareProfessionals: (
    professionalType: ProfessionalType,
    cpf: string,
  ) => Promise<AxiosResponse<TelemedicineHealthcareProfessional[]>>;
  healthcareProfessionalsSchedules: (
    doctorId: number,
    cpf: string,
  ) => Promise<AxiosResponse<DoctorSchedule[]>>;
  doctorSchedules: (doctorId: number) => Promise<AxiosResponse<DoctorSchedule[]>>;
  telemedicineAppointment: (
    data: TelemedicineAppointmentPayload,
  ) => Promise<AxiosResponse<{ id: number }>>;
  requestAddress: (
    _city: string,
    _city_id: number,
    _state: string,
    _state_id: number,
    _neighborhood: string,
    _neighborhood_id: number,
    _cpf: string,
  ) => Promise<any>;
  updateAttachments: (_appointment_id: string, file: any) => Promise<any>;
  getAddressByCep: (_cep: string) => Promise<any>;
  accessByProduct: (_cpf: string, _onix?: object) => Promise<any>;
  getUsertokenCto: (_cpf: string) => Promise<UserToken>;
  accessesBrasilTelecom: (
    name: string,
    cpf: string,
    email: string,
    phone_number: string,
    gender: string,
    birthdate: string,
    zip_code: string,
    address: string,
    number: string,
    neighborhood: string,
    city: string,
    state: string,
    pay_type: number,
    onix_code: string,
  ) => Promise<any>;
  getScheduleDays: (_month: string, _specialtyId: string) => Promise<any>;
  getScheduleTimes: (_day: string, _specialtyId: string) => Promise<any>;
  getSchedulesConecta: (_cpf: string) => Promise<any>;
  getConectaAppointments: (_cpf: string, _onix: string) => Promise<any>;
  getWomensHealthBella: (
    _name: string,
    _email: string,
    _telephone: string,
    _cpf: string,
    _birthDay: string,
  ) => Promise<AxiosResponse<{ url: string }>>;
  cancelConectaAppointment: (_id: string) => Promise<any>;
  getWomensHealthBella: (
    _name: string,
    _email: string,
    _telephone: string,
    _cpf: string,
    _birthDay: string,
  ) => Promise<any>;
  validateDeepLink: (deeplinkCode: string) => Promise<AxiosResponse<ValidateDeeplinkRes>>;
}

export default createContext<ApiInstance>({
  login: async (_documentNumber: string, _password: string) => ({
    data,
    message,
    headers,
  }),
  checkFirstAccess: async (_documentNumber: string) => ({ data, message }),
  passwordFirstAccess: async (_documentNumber: string, _password: string, _code: string) => ({
    data,
    message,
  }),
  checkToken: async (_code: number) => ({ data, message }),
  sendToken: async (_documentNumber: string, _onixcCde?: string, _smsHashcode?: string[]) => ({
    data,
    message,
  }),
  myProducts: async () => ({ data, message }),
  specialities: async () => ({ data, message }),
  findSpecialtyByName: async () => ({ data, message }),
  getMyAppointments: async () => ({ data, message }),
  getMyAppointmentById: async () => ({ data, message }),
  states: async () => ({ data, message }),
  cities: async (_id: number) => ({ data, message }),
  neighborhoods: async (_id: number) => ({ data, message }),
  createSchedule: async (_payload: any) => ({ data, message }),
  cardList: async (_user: any) => ({ data }),
  updateProfile: async () => ({ data, message }),
  cardStatement: async (_documentNumber: string, _cardNumber: string) => ({
    data,
  }),
  cardBalance: async (_documentNumber: string, _cardNumber: string) => ({ data }),
  myProfile: async () => ({ data, message }),
  updatePassword: async () => ({ data, message }),
  updateProfileAddress: async () => ({ data, message }),
  updateTemPayPassword: async () => ({ data, message }),
  recharge: async () => ({ data }),
  rechargeWithRegisteredCreditCard: async () => ({ data }),
  sendingSurgicalProcedure: async () => ({ data }),
  createCreditCard: async () => ({ data }),
  deleteCreditCard: async () => ({ data }),
  getCreditCards: async () => ({ data }),
  searchZipCode: async (_address: string) => ({ data }),
  searchByLatLng: async (_lat: number, _lng: number) => ({ data }),
  createDependent: async () => ({ data, message }),
  getDependents: async () => ({ data, message }),
  updateDependent: async () => ({ data, message }),
  deleteDependent: async () => ({ data, message }),
  searchDrugstores: async (_payload: SearchDrugstoresPayload) => ({ data, message }),
  searchTemFarma: async (_payload: SearchTemFarmaPayload) => ({ data, message }),
  searchCertifiedTemRede: async (_payload: SearchCertifiedTemRedePayload) => ({ data, message }),
  searchNetwork: async (_payload: SearchNetworkPayload) => ({ data, message }),
  getPlanejadinDeeplink: async (_cpf: string) => {},
  luckyNumber: async (_cardNumber: string) => ({ data, message }),
  lotteryNumbers: async () => ({ data, message }),
  hasPrivacyPolicy: async (_cardNumber: string, _documentNumber: string) => ({ data, message }),
  returnPrivacyPolicy: async () => ({ data, message }),
  acceptPrivacyPolicy: async (_cardNumber: string, _documentNumber: string, _ip: string) => ({
    data,
    message,
  }),
  getBenefits: async (_cardNumber: string | undefined) => ({ data, message }),
  getPartner: async (_onix_code: string | undefined) => ({ data, message }),
  getWomensHealthBella: async (
    _name: string,
    _email: string,
    _telephone: string,
    _cpf: string,
    _birthDay: string,
  ) => ({
    data,
    message,
  }),
  telemedicine24h: async (_documentNumber: string) => {},
  telemedicine24hUrl: async (_patientId: number) => {},
  conexaCancelQueue: async () => {},
  telemedicineSpecialties: () => {},
  specialtyDoctors: (specialtyId: number) => {},
  healthcareProfessionals: (professionalType: ProfessionalType, cpf: string) => {},
  healthcareProfessionalsSchedules: (doctorId: number, cpf: string) => {},
  doctorSchedules: (doctorId: number) => {},
  telemedicineAppointment: (data: TelemedicineAppointmentPayload) => {},
  requestAddress: async (
    _city: string,
    _city_id: number,
    _state: string,
    _state_id: number,
    _neighborhood: string,
    _neighborhood_id: number,
    _cpf: string,
  ) => ({ data, message }),
  updateAttachments: async (_appointment_id: string, file: any) => ({ data, message }),
  getAddressByCep: async (_cep: string) => ({ data, message }),
  accessByProduct: async (_cpf: string, _onix?: object) => ({ data, message }),
  getOTPKey: async () => ({ data, message }),
  getUsertokenCto: async (_cpf: string) => ({ data }),
  accessesBrasilTelecom: async (
    _name: string,
    _cpf: string,
    _email: string,
    _phone_number: string,
    _gender: string,
    _birthdate: string,
    _zip_code: string,
    _address: string,
    _number: string,
    _neighborhood: string,
    _city: string,
    _state: string,
    _pay_type: number,
    _onix_code: string,
  ) => ({ data, message }),
  getScheduleDays: async (_month: string, _specialtyId: string) => ({ data, message }),
  getScheduleTimes: async (_day: string, _specialtyId: string) => ({ data, message }),
  getSchedulesConecta: async (_cpf: string) => ({ data, message }),
  getConectaAppointments: async (_cpf: string, _onix: string) => ({
    data,
    message,
  }),
  cancelConectaAppointment: async (_id: string) => ({
    data,
    message,
  }),
  getWomensHealthBella: async (
    _name: string,
    _email: string,
    _telephone: string,
    _cpf: string,
    _birthDay: string,
  ) => ({
    data,
    message,
  }),
});

import styled from 'styled-components/native';
import { Animated } from 'react-native';

export const Wrapper = styled.View<{ isFullBorder: boolean }>`
  border-top-width: 1px;
  border-left-width: ${({ isFullBorder }) => (isFullBorder ? '1px' : '0px')};
  border-right-width: ${({ isFullBorder }) => (isFullBorder ? '1px' : '0px')};
  border-bottom-width: 1px;
  border-color: ${({ theme: { colors } }) => colors.background};
  margin-bottom: ${({ isFullBorder }) => (isFullBorder ? '12px' : '0px')};
  border-radius: ${({ isFullBorder }) => (isFullBorder ? '8px' : '0px')};
`;

export const WrapperHeader = styled.TouchableOpacity<{ toggle?: boolean }>`
  padding-top: ${({ theme }) => theme.tokens.space.s16};
  padding-left: ${({ theme }) => theme.tokens.space.s4};
  padding-right: ${({ theme }) => theme.tokens.space.s4};
  padding-bottom: ${({ theme, toggle }) =>
    toggle ? theme.tokens.space.s16 : theme.tokens.space.s16};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperHeaderIcon = styled(Animated.View)`
  justify-content: center;
  align-items: flex-end;
`;

export const WrapperContent = styled.View`
  padding-left: ${({ theme }) => theme.tokens.space.s4};
  padding-right: ${({ theme }) => theme.tokens.space.s4};
  padding-bottom: 24px;
`;

import React from 'react';
import { Platform, View, StyleSheet, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import desktopBackground from '../../assets/images/surgicalProcedure/desk.png';
import mobileBackground from '../../assets/images/surgicalProcedure/mobile.png';
import * as S from './styles';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Typography, Button } from '~/components/@hello-ui';
import { goBack } from '~/helpers';
import ScalpelIcon from '~/components/Icons/Scalpel';
import PhoneCall from '~/components/Icons/PhoneCall';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

export const SurgicalProcedure = (): JSX.Element => {
  const navigation = useNavigation();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  const handleButtonClick = () => {
    const phoneNumber = 'tel:08008368836';
    Linking.openURL(phoneNumber).catch((err) => {
      console.error('Failed to open phone dialer:', err);
    });
  };

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <ScalpelIcon width={26} height={26} color="white" />,
        title: 'Procedimentos Cirúrgicos',
        onBack: () => {
          goBack(navigation, 'Dashboard');
        },
      }}>
      <View style={styles.container}>
        <Typography variant="body2" style={styles.boldText}>
          Se precisar, você não fica na mão. Temos descontos e facilidades de parcelamento para procedimentos cirúrgicos.
        </Typography>
        <View style={styles.centeredContent}>
          <Typography variant="body2" style={styles.additionalText}>
            Para utilizar o benefício, ligue para a Central de Atendimento:
          </Typography>
          <Button
            variant="primary"
            onPress={handleButtonClick}
            style={styles.button}
          >
            <PhoneCall width={16} height={16} color="white" style={styles.icon} />
            Ligar
          </Button>
        </View>
      </View>
    </PageWithCard>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  boldText: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  centeredContent: {
    alignItems: 'center',
  },
  additionalText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  button: {
    marginTop: 20,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  icon: {
    marginRight: 8,
  },
});

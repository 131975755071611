import * as React from 'react';
import Svg, { Path, G, Mask, Defs, ClipPath } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export function TrashCircleIcon({ width, height }: IconProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <Path
        d="M.5 24C.5 11.021 11.021.5 24 .5S47.5 11.021 47.5 24 36.979 47.5 24 47.5.5 36.979.5 24z"
        stroke="#EC6338"
      />
      <G clipPath="url(#clip0_15086_46715)">
        <Mask id="a" fill="#fff">
          <Path d="M33 16h-3.1a5.01 5.01 0 00-4.9-4h-2a5.01 5.01 0 00-4.9 4H15a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V18h1a1 1 0 000-2zm-10-2h2a3.007 3.007 0 012.829 2h-7.658A3.006 3.006 0 0123 14zm7 17a3 3 0 01-3 3h-6a3 3 0 01-3-3V18h12v13z" />
        </Mask>
        <Path
          d="M33 16h-3.1a5.01 5.01 0 00-4.9-4h-2a5.01 5.01 0 00-4.9 4H15a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V18h1a1 1 0 000-2zm-10-2h2a3.007 3.007 0 012.829 2h-7.658A3.006 3.006 0 0123 14zm7 17a3 3 0 01-3 3h-6a3 3 0 01-3-3V18h12v13z"
          fill="#EC6338"
        />
        <Path
          d="M22 30a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM26 30a1 1 0 001-1v-6a1 1 0 10-2 0v6a1 1 0 001 1z"
          fill="#EC6338"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_15086_46715">
          <Path fill="#fff" transform="translate(12 12)" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { Platform, TouchableOpacity, View } from 'react-native';

import { clsx } from 'clsx';
import { useAuth } from '~/auth/legacy/useAuth';
import { Button, Collapse, Loader, PageWithCard, Typography } from '~/components/@hello-ui';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { InformationCircleIcon } from '~/components/Icons/InformationCircleIcon';
import { toCamelCase } from '~/utils/strings';
import { EditCircleIcon } from '~/components/Icons/EditCircleIcon';
import { TrashCircleIcon } from '~/components/Icons/TrashCircleIcon';

export const Dependents = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { dependents, loadingDependets } = useAuth();
  const { isDeepLink } = DeepLink.useParams();
  const [activeDependents, setActiveDependents] = useState([]);
  const dependentsLimit = theme.dependents_limit === '' ? 0 : theme.dependents_limit;
  const maxDependentsReached = activeDependents.length >= dependentsLimit;

  const handleGoBack = () => {
    if (isDeepLink) {
      DeepLink.sendEvent(DeepLinkEvent.Cancel);
    } else {
      navigation.canGoBack()
        ? navigation.goBack()
        : navigation.navigate('DependentsPresentation', { fromDependents: true });
    }
  };

  useEffect(() => {
    const result = dependents?.length
      ? dependents?.filter((d, idx) => d.status !== 3 && idx < parseInt(dependentsLimit))
      : [];

    setActiveDependents(result);
  }, [dependents]);

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Dependentes',
        onBack: isDeepLink ? undefined : handleGoBack,
      }}>
      <Typography
        variant="subtitle"
        className="mb-8 mt-24 text-[18px] mobile:mt-0 mobile:text-[16px]">
        Gerenciar dependentes
      </Typography>
      <Typography variant="body2" className="mb-8 text-[16px] mobile:mb-16 mobile:text-[14px]">
        Visualize, cadastre, atualize ou remova seus dependentes.
      </Typography>

      {loadingDependets && <Loader variant="circle-loader" />}
      {!loadingDependets && (activeDependents || []).length === 0 ? (
        <View
          className="mb-16 flex flex-row rounded-lg p-24"
          style={{ backgroundColor: '#DEEAF4' }}>
          <InformationCircleIcon width={24} height={24} />
          <Typography variant="titleXsmallBold" style={{ color: '#2374C5', marginLeft: 10 }}>
            Sem dependentes cadastrados.
          </Typography>
        </View>
      ) : (
        activeDependents?.map((dependent, index) => (
          <Collapse
            key={dependent.id || `dependent-${index}`}
            title={toCamelCase(dependent.name)}
            height={56}
            isFullBorder={true}>
            <View className="mt-2 flex flex-row items-center justify-between">
              <View className="w-3/4 mobile:w-4/6">
                <Button
                  variant="primary"
                  iconPosition="left"
                  className="h-[48px]"
                  onPress={() => navigation.navigate('DependentsView', { cpf: dependent.cpf })}
                  icon={<FeatherIcon name="eye" size={24} color={theme?.colors.white} />}
                  textStyle={{
                    fontFamily: Platform.OS === 'web' ? 'Poppins' : 'Poppins-Bold',
                    fontWeight: '600',
                    fontSize: 19,
                    lineHeight: 28,
                    color: theme.colors.white,
                  }}>
                  Visualizar
                </Button>
              </View>

              <View className="flex w-1/4 flex-row content-center justify-end gap-3 mobile:w-4/12">
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('EditDependents', {
                      cpf: dependent?.cpf,
                    })
                  }>
                  <EditCircleIcon width={48} height={48} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('DependentsReason', {
                      cpf: dependent?.cpf,
                    })
                  }>
                  <TrashCircleIcon width={48} height={48} />
                </TouchableOpacity>
              </View>
            </View>
          </Collapse>
        ))
      )}

      <Button
        variant="outline"
        iconPosition="left"
        className="mt-16 h-[48] max-h-[48px] w-[530px] max-w-[530px] justify-center px-24 py-16 mobile:w-full mobile:py-12"
        onPress={() => navigation.navigate('CreateDependents')}
        icon={
          <FeatherIcon
            name="plus"
            size={24}
            color={maxDependentsReached ? theme.colors['primary20'] : theme?.colors.primary}
          />
        }
        textStyle={{
          fontFamily: Platform.OS === 'web' ? 'Poppins' : 'Poppins-Bold',
          fontWeight: maxDependentsReached ? '500' : '600',
          fontSize: 19,
          lineHeight: 28,
          color: maxDependentsReached ? theme.colors['orange-disabled'] : theme.colors.primary,
        }}
        disabled={maxDependentsReached}>
        Cadastrar dependente
      </Button>
      <>
        {maxDependentsReached && (
          <Typography variant="helperText" color={theme.colors.warning} className="mt-8 pl-20">
            Você atingiu o limite máximo de dependentes cadastrados.
          </Typography>
        )}
      </>
    </PageWithCard>
  );
};

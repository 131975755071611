import React, { useState, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Typography } from '../Typography';
import { CollapseProps } from './types';
import * as S from './styles';

export const Collapse = ({
  title,
  children,
  height,
  isFullBorder = false,
}: CollapseProps): JSX.Element => {
  const theme = useTheme();
  const [toggle, setToggle] = useState<boolean>(false);
  const animatedValue = useRef(new Animated.Value(0)).current;

  const rotate = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg'],
  });

  useEffect(() => {
    Animated.spring(animatedValue, {
      toValue: toggle ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [toggle]);

  return (
    <S.Wrapper isFullBorder={isFullBorder}>
      <S.WrapperHeader
        onPress={() => setToggle(!toggle)}
        toggle={toggle}
        style={{ height: height || 80 }}>
        <Typography variant="titleXsmallBold">{title}</Typography>
        <S.WrapperHeaderIcon style={{ transform: [{ rotate }] }}>
          <Icon name="chevron-down" size={24} color={theme?.colors?.primary} />
        </S.WrapperHeaderIcon>
      </S.WrapperHeader>
      {toggle && <S.WrapperContent>{children}</S.WrapperContent>}
    </S.Wrapper>
  );
};
import React, { useState, useEffect } from 'react';
import Icon from 'react-native-vector-icons/dist/Feather';
import IconFa from 'react-native-vector-icons/dist/FontAwesome';
import IconMI from 'react-native-vector-icons/dist/MaterialIcons';

import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import * as S from '../styles';
import { Typography } from '../../../components/@tem-ui';
import { scheduleTypes, TypesEnum } from '../../../enums/appointment';
import { useAppointment } from '../../../hooks/appointment';
import { AppointmentTypes } from '../../../contexts/types';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';

const iconTypes: JSX.Element[] = [
  <IconFa name="stethoscope" size={32} color={StyledDefaultTheme.colors.blue} />,
  <Icon name="video" size={32} color={StyledDefaultTheme.colors.blue} />,
  <Icon name="file-text" size={32} color={StyledDefaultTheme.colors.blue} />,
  <Icon name="smartphone" size={32} color={StyledDefaultTheme.colors.blue} />,
  <Icon name="video" size={32} color={StyledDefaultTheme.colors.blue} />,
  <Icon name="video" size={32} color={StyledDefaultTheme.colors.blue} />,
  <IconFa name="stethoscope" size={32} color={StyledDefaultTheme.colors.blue} />,
];

export const Card = (): JSX.Element => {
  const { appointment } = useAppointment();
  const [currentAppointment, setCurrentAppointment] = useState<AppointmentTypes | null>();
  const navigation = useNavigation();
  const theme = useTheme();
  const { onixCode } = useAuth();
  const onixConecta = [];
  const isConectaTelemedicine =
    onixConecta?.includes(onixCode?.onix_code) && currentAppointment?.type === 1;

  const isAppoitmentPersonalBmg =
    onixConecta?.includes(onixCode?.onix_code) && currentAppointment?.type === 0;

  const shouldShowViewApointment = () => {
    if (isConectaTelemedicine) return true;
    if (isAppoitmentPersonalBmg) return false;

    return (
      currentAppointment?.type !== TypesEnum.Exam &&
      currentAppointment?.type !== TypesEnum.Video &&
      currentAppointment?.type !== TypesEnum.Terapiaonline &&
      currentAppointment?.type !== TypesEnum.Surgical &&
      currentAppointment?.type !== undefined
    );
  };

  useEffect(() => {
    if (appointment) {
      setCurrentAppointment(appointment);
    }
  }, [appointment]);

  return (
    <S.ContainerCard>
      <S.WrapperCard>
        <S.WrapperCardContent>
          <S.WrapperCardIcon>
            {!isConectaTelemedicine && iconTypes[currentAppointment?.type ?? TypesEnum.Appointment]}
            {isConectaTelemedicine && (
              <IconMI name="personal-video" size={32} color={StyledDefaultTheme.colors.blue} />
            )}
          </S.WrapperCardIcon>
          <S.WrapperCardHeader>
            <Typography variant="body1" color="black" bold>
              {scheduleTypes[currentAppointment?.type ?? ''] ?? '-'}
            </Typography>
            <Typography variant="body2" color="black">
              {currentAppointment?.specialty && currentAppointment?.specialty.name}
              {currentAppointment?.type === TypesEnum.Terapiaonline && 'Psicologia'}
            </Typography>
          </S.WrapperCardHeader>
        </S.WrapperCardContent>

        <S.WrapperCardActions>
          {shouldShowViewApointment() && (
            <S.ActionRight
              onPress={() => {
                navigation.navigate('MyAppointments');
              }}>
              <Typography
                variant="body2"
                color={theme?.colors.primary}
                bold
                style={{
                  textDecorationColor: StyledDefaultTheme.colors.primary,
                  textDecorationLine: 'underline',
                }}>
                Acompanhar solicitação
              </Typography>
              <IconFa
                name="chevron-right"
                size={15}
                color={StyledDefaultTheme.colors.primary}
                style={{
                  position: 'absolute',
                  right: Platform.OS === 'web' ? 170 : 55,
                  top: Platform.OS === 'web' ? 18 : 15,
                }}
              />
            </S.ActionRight>
          )}
        </S.WrapperCardActions>
      </S.WrapperCard>
    </S.ContainerCard>
  );
};

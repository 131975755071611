import React from 'react';
import { Circle, G, Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

type IconProps = {
  width: number;
  height: number;
  color?: string;
};

export const Dentistry24HoursIcon = ({ width, height, color }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M22.265 10.46c-.468 0-.876.317-.996.77-1.037 3.936-2.99 7.607-4.578 10.33a.91.91 0 01-1.69-.47v-6.045c0-1.648-1.311-3.022-2.96-3.044a3 3 0 00-3.039 3v6.089a.91.91 0 01-1.69.469c-2.279-3.904-5.308-9.764-5.308-15.557 0-.163.045-3.999 3.999-3.999.696 0 1.563.231 2.395.311.582.056.963-.33 1.048-.848C9.53.888 9.266.471 8.43.237 7.647.018 6.82.005 6.003.004A5.784 5.784 0 00.004 6.003c0 6.277 3.186 12.46 5.58 16.563a2.91 2.91 0 005.418-1.475v-6.066c0-.542.422-1.004.963-1.023a1 1 0 011.037 1v6.089a2.912 2.912 0 005.417 1.475c1.693-2.901 3.641-6.62 4.766-10.886.153-.583-.026-.991-.445-1.158a.956.956 0 00-.355-.062h-.119z"
        fill={theme.colors[color]}
      />
      <Path
        d="M14.601 6.303c-.3.226-.62.464-.89.7h2.29a1 1 0 110 1.999h-4a1 1 0 01-1-1c0-1.5 1.276-2.455 2.4-3.3.75-.561 1.6-1.199 1.6-1.699a1 1 0 10-2 0 1 1 0 01-1.999 0 2.998 2.998 0 115.998 0c0 1.5-1.274 2.456-2.399 3.3zM22.999.004a1 1 0 00-1 1v3h-1a1 1 0 01-.999-1v-2a1 1 0 00-2 0v2a3 3 0 003 2.999h1v2a1 1 0 102 0v-7a1 1 0 00-1-.999h-.001z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

import React from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsMindAndBodyProgram/desktop.png';
import mobileBackground from '../../assets/images/benefitsMindAndBodyProgram/mobile.png';
import { Collapse, PageWithCard, Typography } from '~/components/@hello-ui';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import HeadSideMedicalIcon from '~/components/Icons/HeadSideMedical';
import { HealthProfessional } from '~/components/HealthProfessional';
import { Spacing } from '~/components/Spacing';

export const BenefitsMindAndBodyProgram = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const backgroundImage = theme.isMobile ? mobileBackground : desktopBackground;
  return (
    <>
      <PageWithCard
        variant={PageWithCardVariant.FeaturedImage}
        featuredImageOptions={{
          backgroundImage,
          titleStartContent: <HeadSideMedicalIcon color={theme.colors.white} />,
          title: 'Programa Mente e Corpo',
          onBack: () => {
            navigation.goBack();
          },
        }}>
        <View>
          <Typography variant="body2">
            Receba apoio para realizar mudanças positivas em sua{' '}
            <Typography variant="bodyHighlight2">alimentação</Typography>,{' '}
            <Typography variant="bodyHighlight2">corpo</Typography> e{' '}
            <Typography variant="bodyHighlight2">mente</Typography>. Tenha acompanhamento de
            profissionais especializados para montar seu cardápio e cuidar da sua saúde mental.
          </Typography>
        </View>

        <View className="pt-24">
          <Collapse title="Saiba mais">
            <View className="mb-8 space-y-8">
              <Typography variant="body2">
                No Programa Mente e Corpo você realiza{' '}
                <Typography variant="bodyHighlight2">consultas mensais</Typography> com
                nutricionista e psicólogo e também pode tirar suas dúvidas do dia a dia na área de
                suporte na Central de Atendimento.
              </Typography>
            </View>
          </Collapse>
        </View>
        <Spacing top={16}>
          <HealthProfessional
            benefit="programa_mente_e_corpo"
            title="Consultas por Vídeo Agendada"
            subtitle="Agende sua Consulta por Vídeo sozinho as especialidades:"
            items={[
              {
                professionalType: 'PSYCHOLOGIST',
                label: 'Psicologia',
              },
              {
                professionalType: 'NUTRITIONIST',
                label: 'Nutrição',
              },
            ]}
          />
        </Spacing>
      </PageWithCard>
    </>
  );
};

import React, { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { Platform, View } from 'react-native';
import {
  Button,
  Collapse,
  Dialog,
  MobileAutoSpace,
  PageWithCard,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import desktopBackground from '~/assets/images/benefitsFinancialEducation/desktop.png';
import mobileBackground from '~/assets/images/benefitsFinancialEducation/mobile.png';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import Calculator from '~/components/Icons/Calculator';
import { useApi } from '~/hooks/api';
import WebView from 'react-native-webview';
import { useAuth } from '~/auth/legacy/useAuth';

export const FinancialEducation = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<null | string>(null);
  const { user } = useAuth();
  const api = useApi();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const handleGetUrl = useCallback(async () => {
    try {
      setLoading(true);
      const res = await api.getPlanejadinDeeplink(user?.cpf);
      const url = res.data?.url;
      setUrl(url);
    } catch (error) {
      console.log(error);
      showSweetAlert('Ops, algo deu errado', 'Tente novamente mais tarde', 'error', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'OK',
            variant: 'primary',
            onPress: () => hideSweetAlert(),
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  }, [user]);

  const theme = useTheme();
  const navigation = useNavigation();

  const backgroundImage = theme.isMobile ? mobileBackground : desktopBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        title: 'Programa de Educação Financeira',
        backgroundImage: backgroundImage,
        titleStartContent: <Calculator color="white" height={24} width={24} />,
        onBack: () => {
          navigation.navigate('BenefitsFinancial');
        },
      }}>
      <View className="mb-24">
        <Typography variant="body2">
          Trilhas de aprendizagem para transformar a sua relação com o dinheiro, preparando você
          para alcançar a segurança financeira.
        </Typography>
      </View>
      <Collapse title="Saiba mais">
        <View className="mb-8">
          <Typography variant="body2">
            Programa digital com vídeo-aulas, e-books e consultoria personalizada para transformar
            sua gestão financeira.
          </Typography>
        </View>
        <View>
          <Typography variant="body2" className="mb-8">
            Você vai aprender:
          </Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Gestão Financeira: Controle melhor seu dinheiro e tome decisões assertivas.
          </Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Metas Financeiras: Defina objetivos de curto, médio e longo prazo.
          </Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Reserva de Emergência: Construa uma reserva para imprevistos.
          </Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Investimentos: Aprenda a investir com segurança e rentabilidade.
          </Typography>
        </View>

        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Reprogramação Financeira: Mude seus hábitos e crenças financeiras.
          </Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Planejamento de Aposentadoria: Prepare-se para uma aposentadoria tranquila.
          </Typography>
        </View>
        <View className="mb-8 mobile:mb-16">
          <Typography variant="body2">
            • Proteção Patrimonial: Proteja seus bens e evite riscos financeiros.
          </Typography>
        </View>
      </Collapse>
      <Collapse title="Como utilizar?">
        <View className="mb-8">
          <Typography variant="body2">
            Você terá direito a uma plataforma online com vídeo-aulas, e-books e 2 consultorias
            personalizadas com especialista por ano, cada uma com duração de 45 minutos.
          </Typography>
        </View>
      </Collapse>

      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Typography
        variant="bodyHighlight2"
        className="mb-24 max-w-[530px] self-center text-center mobile:mb-16">
        Para utilizar o benefício, acesse a plataforma:
      </Typography>

      <Button loading={loading} style={{ height: 48 }} variant="primary" onPress={handleGetUrl}>
        Acessar
      </Button>
      <Dialog visible={!!url} onClose={() => setUrl(null)}>
        {url &&
          (Platform.OS !== 'web' ? (
            <WebView
              nestedScrollEnabled={true}
              scrollEnabled={true}
              javaScriptEnabled={true}
              overScrollMode="always"
              source={{ uri: url }}
              style={{ flex: 1, minHeight: 200, height: 650, opacity: 0.99 }}
            />
          ) : (
            <iframe
              src={url}
              style={{ width: '100%', height: '80vh', border: 'none' }}
              allow="camera; microphone"
            />
          ))}
      </Dialog>
    </PageWithCard>
  );
};

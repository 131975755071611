import React, { memo, useCallback, useEffect } from 'react';
import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { useAuth } from '~/auth/legacy/useAuth';
import { Button, Stepper, Typography, useSweetAlert } from '~/components/@hello-ui';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { useUploader } from '~/components/@tem-ui/Uploader';
import { trackingEventType } from '~/contexts/tracking/types';
import { TelemedicineScheduleDate } from '../VideoAppointmentsSchedule/conexa-telemedicine/TelemedicineScheduleDate/Index';
import { SelectDependents } from '../ScheduleAppointment/components/SelectDependents/SelectDependents';
import { ScheduleReview } from '../ScheduleAppointment/components/ScheduleReview';
import { TypesEnum } from '~/enums/appointment';
import { ScheduleSuccess } from '../ScheduleSuccess';
import { ScheduleTelemedicineHealthcareProfessional } from '../VideoAppointmentsSchedule/conexa-telemedicine/ScheduleTelemedicineHealthcareProfessional';
import { Spacing } from '~/components/Spacing';
import { TelemedicineHelthcareProfessionalsScheduleDate } from '../VideoAppointmentsSchedule/conexa-telemedicine/TelemedicineHelthcareProfessionalsScheduleDate/Index';

const BenefitsVideoAppointmentsSchedule = ({ route }: any): JSX.Element => {
  const { checkIsEnabledFeature } = useAuth();
  const { benefit, professionalType } = route.params || {};
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { currentStep, stepper, finalizeSteps, setCurrentStepState, toStepper } = useStepper();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { clearAppointments } = useAppointment();
  const { clearUploader } = useUploader();
  const { submitTelemedicineAppointment } = useAppointment();
  const cardBenefits = [
    {
      tracking: trackingEventType.ClickedOnVideoAppointment,
      benefit: 'programa_mente_e_corpo',
      title: 'Programa Mente e Corpo',
      visibilitySteps: false,
      steps: [
        <ScheduleTelemedicineHealthcareProfessional
          key="ScheduleTelemedicineDoctor"
          professionalType={professionalType}
        />,
        <TelemedicineHelthcareProfessionalsScheduleDate key="TelemedicineScheduleDate" />,
        <SelectDependents key="SelectDependent" />,
        <ScheduleReview
          key="ScheduleReview"
          scheduleType={TypesEnum.Video}
          trackingType={trackingEventType.RequestedInVideoAppointment}
          customSubmit={submitTelemedicineAppointment}
        />,
        <ScheduleSuccess key="ScheduleSuccess" />,
      ],
      iconSideOfTitle: true,
    },
  ];

  const card = cardBenefits.find(
    (item) => item.benefit === benefit && checkIsEnabledFeature(benefit),
  );

  const showCancelMessage = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja sair da solicitação de agendamento?',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => {
              clearUploader();
              clearAppointments();
              showSweetAlert(
                'Tudo certo!',
                'Sua solicitação foi cancelada',
                'success',
                false,
                false,
                {
                  layout: 'helloUi',
                  buttons: [
                    {
                      variant: 'primary',
                      text: 'Ok',
                      onPress: () => {
                        finalizeSteps();
                        setCurrentStepState(0);
                        navigation.navigate('Dashboard');
                        hideSweetAlert();
                      },
                    },
                  ],
                },
              );
            },
          },
        ],
      },
    );
  };

  useFocusEffect(
    useCallback(() => {
      toStepper(0);
      setCurrentStepState(0);
    }, []),
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: card?.title || '',
        onBack: () => {
          if (currentStep >= 0 && stepper !== null) {
            showCancelMessage();
          } else {
            finalizeSteps();
            navigation.navigate('Dashboard');
          }
        },
      }}>
      {card && stepper != null ? (
        <View className="min-h-full ">
          <Stepper items={[card]} validateDependentFeatures />
        </View>
      ) : (
        <View className="align-center min-h-full flex-col">
          <Spacing bottom={16}>
            <Typography variant="body2" color="paragraph">
              Ocorreu um erro inesperado
            </Typography>
          </Spacing>

          <Button
            variant="primary"
            onPress={() => {
              finalizeSteps();
              setCurrentStepState(0);
              navigation.navigate('Dashboard');
            }}>
            Voltar
          </Button>
        </View>
      )}
    </PageWithCard>
  );
};

export default BenefitsVideoAppointmentsSchedule;

import { ICertificateAuthenticateOptions } from '~/auth/strategies/certificate/interfaces/certificate-authenticate-options';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';
import { BaseStrategy } from '~/auth/strategies/base-strategy';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';
import { useAuthStore } from '~/store/auth.store';
import { navigationRef } from '~/router/navigator';
import { CompleteDataSSOGatekeeper } from '~/auth/gatekeepers/complete-data-sso-gatekeeper';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export class CertificateStrategy extends BaseStrategy {
  constructor() {
    super();
  }

  static StrategyName = EAuthStrategy.Certificate;

  override mountAuthorizedRoutes(): Routes[] {
    return [
      'Dashboard',
      'TemPayPresentation',
      'TemPayDashboard',
      'TemPayRecharge',
      'TemPayRechargeBillet',
      'TemPayRechargeCard',
      'AttendanceWebChat',
      'TemPayStatement',
      'TemPayPassword',
      'TemPayHelp',
      'FinancialTeleorientation',
      'BenefitsTeleOrientation',
      'FinancialEducation',
      'BenefitsNutritionalAssistance',
      'PsychologicalAssistance',
      'SurgicalProcedure',
      'SurgicalProcedureForm',
      'SurgicalProcedureSuccess',
      'SurgicalProcedureHospital',
      'ScheduleAppointmentSuccess',
      'ScheduleTeleorientation',
      'RegulationAndPrivacy',
      'MyAppointments',
      'Dependents',
      'DependentsView',
      'DependentsReason',
      'HelpDependents',
      'Signature',
      'SignatureCancel',
      'Profile',
      'UpdateProfileData',
      'ProfileData',
      'ProfilePassword',
      'CreateDependents',
      'EditDependents',
      'UpdateProfileAddress',
      'ProfileAddress',
      'DependentsPresentation',
      'BenefitsPresentation',
      'BenefitsFunerals',
      'PsychologicalTherapy',
      'PharmacyBenefit',
      'BenefitsNetwork',
      'BenefitsServicesConsultations',
      'MyClinic',
      'MyCreditCards',
      'RegisterCreditCard',
      'BenefitsNetworkSearch',
      'BenefitsDiscountMedicines',
      'BenefitsDiscountMedicinesSearch',
      'BenefitsDigitalWellBeing',
      'BenefitsDigitalWellBeingSSO',
      'BenefitsHealthyHabitsProgram',
      'BenefitsHospitalAdmision',
      'BenefitsMentalHealthProgram',
      'BenefitsMindAndBodyProgram',
      'BenefitsWellBeing',
      'BenefitsWoman',
      'BenefitsInsurance',
      'BenefitsInsuranceDeathDisability',
      'BenefitsCapitalization',
      'BenefitsPetAssistance',
      'BenefitsPetFuneralAssistance',
      'BenefitsPetEmergencyAppointment',
      'BenefitsPetTransport',
      'BenefitsPetFoodShipping',
      'BenefitsPetAccommodation',
      'BenefitsCheckup',
      'BenefitsResidentialAssistant',
      'BenefitsParentalAssistance',
      'BenefitsPetAssistant',
      'BenefitsBusinessAssistant',
      'SubsidizedMedicines',
      'BenefitsWelcomingWomen',
      'BenefitsWomensHealth',
      'BenefitsDigitalFemaleAssistance',
      'BenefitsResidentialAssistancePlumber',
      'BenefitsKeychain',
      'BenefitsResidentialAssistanceElectrician',
      'BenefitsResidentialAssistanceHomeAppliance',
      'BenefitsBusinessAssistancePlumber',
      'BenefitsBusinessAssistanceKeychain',
      'BenefitsBusinessAssistanceElectrician',
      'BenefitsBusinessAssistanceHomeAppliance',
      'BenefitsFinancial',
      'VideoAppointmentsSchedule',
      'BenefitsVideoAppointmentsSchedule',
      'BenefitsFinancialClub',
      'MyBenefits',
    ];
  }

  getGateKeepers(): IGatekeeper[] {
    return [new CompleteDataSSOGatekeeper()];
  }

  override async onGatekeepersPass(): Promise<void> {
    await super.onGatekeepersPass();
    navigationRef.navigate('Dashboard');
  }

  async authenticate(options: ICertificateAuthenticateOptions): Promise<void> {
    const res = await OlimpoService.ssoCertificate.authenticateCertificate(options);

    useAuthStore.setState({
      token: res.token,
      refreshToken: res.refreshToken,
    });

    await this.updateMyProducts();
    await this.updateProfileData();
    await this.selectProduct(res.onixCode);
  }

  override async onTokenExpire() {
    await super.onTokenExpire();
    navigationRef.navigate('DeepLinkLoggedOut');
  }
}

import React, { useMemo } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { format, parseISO } from 'date-fns';
import { Button, DetailsList, MobileAutoSpace, PageWithCard } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { toCamelCase } from '~/utils/strings';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';

export const ProfileData = (): JSX.Element => {
  const { user } = useAuth();
  const navigation = useNavigation();
  const { params } = useRoute<UseRoute<'ProfileData'>>();
  const { isDeepLink } = DeepLink.useParams();

  const items = useMemo(
    () => [
      {
        title: 'Nome',
        description: toCamelCase(user?.name ?? '') || '-',
      },
      {
        title: 'CPF',
        description: user?.cpf || '-',
      },
      {
        title: 'E-mail',
        description: user?.email || '-',
      },
      {
        title: 'Celular',
        description: user?.telephone_1 || '-',
      },
      {
        title: 'Data de Nascimento',
        description:
          (user?.birthdate ? format(parseISO(user.birthdate.split('T')[0]), 'dd/MM/yyyy') : '-') ||
          '-',
      },
      {
        title: 'Sexo Biológico',
        description:
          user?.gender === 'indiferente' ? 'Não informado' : toCamelCase(user?.gender ?? '') || '-',
      },
    ],
    [user],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Dados Pessoais',
        onBack: isDeepLink
          ? undefined
          : () => {
              if (params?.returnPage) {
                navigation.navigate(params?.returnPage, { keepState: true });
              } else {
                navigation.navigate('Profile');
              }
            },
      }}>
      <DetailsList items={items} />
      <MobileAutoSpace heightMobile={24} heightDesktop={40} />
      <Button variant="primary" onPress={() => navigation.navigate('UpdateProfileData')}>
        Atualizar dados
      </Button>
    </PageWithCard>
  );
};

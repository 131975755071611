import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import {
  HealthProfessionalItem as HealthProfessionalItemStyled,
  HealthProfessionalText,
} from './styles';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import clsx from 'clsx';

export interface HealthProfessionalItem {
  label: string;
  professionalType: string;
}

interface HealthProfessionalProps {
  item: HealthProfessionalItem;
  roundedTop: boolean;
  roundedBottom: boolean;
  onPress: (professionalType: string) => void;
}

export const HealthProfessionalItem: React.FC<HealthProfessionalProps> = ({
  item: { professionalType, label },
  roundedTop,
  roundedBottom,
  onPress,
}) => {
  const theme = useTheme();
  return (
    <HealthProfessionalItemStyled
      roundedBottom={roundedBottom}
      roundedTop={roundedTop}
      onPress={() => onPress(professionalType)}
      className={clsx('flex-row items-center  bg-background-gray p-16')}>
      <HealthProfessionalText color={theme.colors.paragraph}>{label}</HealthProfessionalText>
      <View className="ml-auto shrink-0 flex-row pl-24">
        <FeatherIcon
          name="chevron-right"
          size={24}
          color={theme?.colors?.primary}
          testID="chevron-icon"
        />
      </View>
    </HealthProfessionalItemStyled>
  );
};
